import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/shared/utils";

const buttonVariants = cva("inline-flex items-center justify-center text-base font-medium transition-colors disabled:pointer-events-none disabled:opacity-50", {
    variants: {
        variant: {
            default: "rounded-md bg-background-brand-secondary text-content-primary-invert hover:bg-background-brand-tertiary focus:rounded-md",
            secondary: "rounded-full bg-background-brand-b-primary text-content-primary-invert hover:bg-background-brand-b-tertiary focus:rounded-full",
            outline: "rounded-md border border-stroke-primary bg-background-primary text-content-secondary hover:bg-background-secondary hover:text-content-primary",
            "outline-secondary":
                "rounded-full border-2 border-stroke-brand text-content-brand hover:bg-background-brand-weakest hover:text-content-brand-secondary focus:rounded-full",
            destructive: "rounded-md bg-background-danger text-content-primary-invert hover:bg-background-danger-secondary",
            ghost: "rounded-md text-content-primary hover:bg-background-secondary",
            link: "rounded-md text-content-primary underline-offset-4 hover:underline"
        },
        size: {
            auto: "h-auto",
            sm: "h-7 px-2 py-1",
            md: "h-11 px-4 py-3"
        }
    },
    defaultVariants: {
        variant: "default",
        size: "md"
    }
});

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
    children: React.ReactNode;
    asChild?: boolean;
    startDecorator?: React.ReactNode;
    endDecorator?: React.ReactNode;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({ type = "button", className, children, startDecorator, endDecorator, variant, size, asChild = false, ...props }, ref) => {
        const Comp = asChild ? Slot : "button";
        const decoratedChildren = (
            <>
                {startDecorator && <span className="mr-2 flex">{startDecorator}</span>}
                {children}
                {endDecorator && <span className="ml-2 flex">{endDecorator}</span>}
            </>
        );

        return (
            <Comp className={cn(buttonVariants({ variant, size, className }))} type={type} ref={ref} {...props}>
                {/* asChild can only have a single child */}
                {asChild ? children : decoratedChildren}
            </Comp>
        );
    }
);
Button.displayName = "Button";

export { Button, buttonVariants };
