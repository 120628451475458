import * as React from "react";
import * as ToggleGroup from "@radix-ui/react-toggle-group";
import { cva } from "class-variance-authority";
import { cn } from "@/shared/utils";

// Define the RadioGroupProps interface
export interface RadioButtonGroupProps {
    initialValue?: string;
    onValueChange?: (value: string) => void;
    options: { value: string; label: string | React.ReactNode; icon?: React.ReactNode }[];
    disabled?: boolean;
    error?: string;
    className?: string;
}

// TODO: add height variant for Root
const radioGroupRoot = cva("flex h-11 flex-row rounded-md text-xs text-content-primary disabled:cursor-wait");
const radioGroupItem = cva(
    "flex w-full cursor-pointer flex-col items-center justify-center whitespace-nowrap p-1 text-center focus:rounded-none first:focus:rounded-l-md first:focus:rounded-r-none last:focus:rounded-l-none last:focus:rounded-r-md disabled:cursor-wait sm:px-3",
    {
        variants: {
            state: {
                default: "border-2 border-stroke-transparent bg-background-secondary hover:bg-background-tertiary",
                selected: "border-stroke-brand-secondary border-2 border-stroke-brand-b bg-background-primary font-medium"
            }
        }
    }
);

export const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({ initialValue = "", onValueChange, options, className, error, disabled = false }) => {
    const [value, setValue] = React.useState(initialValue);

    const handleChange = (newValue: string) => {
        if (newValue) {
            setValue(newValue);
            if (onValueChange) {
                onValueChange(newValue);
            }
        }
    };

    return (
        <ToggleGroup.Root type="single" value={value} onValueChange={handleChange} disabled={disabled} className={cn(radioGroupRoot(), className)}>
            {options.map((option, index) => {
                const isSelected = value === option.value;
                const variantState = isSelected ? "selected" : "default";
                const radioGroupItemClassConditions = {
                    "rounded-l-md": index === 0,
                    "rounded-r-md": index === options.length - 1,
                    "shadow-faux-border-danger": !!error
                };
                const labelClassConditions = {
                    "cursor-wait": disabled,
                    "cursor-pointer": !disabled,
                    "text-content-secondary": !isSelected
                };

                return (
                    <ToggleGroup.Item
                        key={option.value}
                        value={option.value}
                        disabled={disabled}
                        className={cn(radioGroupItem({ state: variantState }), radioGroupItemClassConditions)}
                    >
                        {option.icon && <span className={isSelected ? "text-content-primary" : "text-content-secondary"}>{option.icon}</span>}
                        <label aria-disabled={disabled} className={cn("text-sm", labelClassConditions)}>
                            {option.label}
                        </label>
                    </ToggleGroup.Item>
                );
            })}
        </ToggleGroup.Root>
    );
};
